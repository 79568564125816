const defaultState = { isMobile: false, isTablet: false, isDesktop: false }

function detectDeviceReducer(state = defaultState, action) {
  switch (action.type) {
    case 'TOGGLE':
      return { isMobile: action.payload, isTablet: false, isDesktop: false }
    case 'TOGGLE_TABLET':
      return { isMobile: false, isTablet: action.payload, isDesktop: false }
    case 'TOGGLE_DESKTOP':
      return { isMobile: false, isTablet: false, isDesktop: action.payload }
    default:
      return state
  }
}

export default detectDeviceReducer
